<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-[70]" @close="$emit('update:open', false );">
            <div class="fixed inset-0" />

            <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
                <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                        <DialogPanel class="pointer-events-auto w-screen max-w-md">
                            <div class="flex h-full flex-col bg-white py-6 shadow-xl">
                                <div class="px-4 sm:px-6 border-b-[1px] pb-4">
                                    <div class="flex items-start justify-between">
                                        <DialogTitle class="text-lg font-semibold leading-6 text-gray-900">Manual</DialogTitle>
                                        <div class="ml-3 flex h-7 items-center">
                                            <button type="button" class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            @click="$emit('update:open', false );">
                                                <span class="absolute -inset-2.5" />
                                                <span class="sr-only">Close panel</span>
                                                <XIcon  class="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class=" overflow-y-scroll ">
                                    <div class="mt-6 px-4 sm:px-6">
                                        <div class="text-blue-600 text-[17px] font-semibold pb-1 border-b-2 border-blue-600 mb-4">
                                            Documents Manual
                                        </div>
                                        <div class="w-full px-2 py-5 bg-white rounded border border-gray-200 flex items-center justify-between">
                                            <div class="flex justify-start gap-3">
                                                <img src="@/assets/img/manual.svg" alt="">
                                                <p class="my-auto">HRnFLEX Manual</p>
                                            </div>
                                            <div>
                                                <a
                                                    :href="require(`@/assets/file/HRnFLEX_Admin_Manual.pdf.png`)"
                                                    download="HRnFLEX_Admin_Manual.pdf"
                                                    class="w-24 h-8 px-3 py-1.5 bg-blue-600 rounded justify-center items-center gap-3 inline-flex">
                                                    <div class="text-center text-neutral-50 text-sm font-semibold leading-tight">Download</div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="w-full mt-2 px-2 py-5 bg-white rounded border border-gray-200 flex items-center justify-between">
                                            <div class="flex justify-start gap-3">
                                                <img src="@/assets/img/manual.svg" alt="">
                                                <p class="my-auto">Payroll Manual</p>
                                            </div>
                                            <div>
                                                <a
                                                    :href="require(`@/assets/file/Payroll_Manual.pdf.png`)"
                                                    download="Payroll_Manual.pdf"
                                                    class="w-24 h-8 px-3 py-1.5 bg-blue-600 rounded justify-center items-center gap-3 inline-flex">
                                                    <div class="text-center text-neutral-50 text-sm font-semibold leading-tight">Download</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4 px-4 sm:px-6 pt-3 ">
                                        <div class="text-blue-600 text-[17px] font-semibold pb-1 border-b-2 border-blue-600 mb-4">Video Tutorials</div>
                                        <template v-for="( step, index ) in videoTutorial" :key="index">
                                            <div class="rounded bg-slate-100 my-2 py-2 px-2">
                                                <p class="text-sm font-semibold leading-normal  "> {{  `Step${index + 1 }` }}</p>
                                                <div class="flex flex-col gap-2 pt-2">
                                                    <template v-for="( tutorials, idx) in step" :key="idx">
                                                        <div class="w-full bg-white py-2 flex justify-between px-2 pt-2 items-center ali">
                                                            <p class="text-sm font-semibold leading-5 text-gray-700">
                                                                {{ tutorials.title }}
                                                            </p>
                                                            <button
                                                                class="rounded bg-indigo-100 font-semibold text-sm leading-5  text-indigo-700 py-[6px] px-3"
                                                                @click="openTutorialsVideo( tutorials )"
                                                            >
                                                                View
                                                            </button>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script >
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon  } from  "@heroicons/vue/solid";

export default {
    props : {
        open : Boolean,
    },
    emits : [ 'update:open'],
    components : {
        Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot
        , XIcon
    },
    methods : {
        openTutorialsVideo( item ){

            window.open( item.url, item.title );
        }
    },
    data(){
        return {
            videoTutorial : [
                [
                    { title : 'Join Process', url : "https://drive.google.com/file/d/1Jk_Z-xKTgDvULdrlHWYymb47V6v-6fde/view?usp=sharing"}
                ],
                [
                    { title : 'Company Settings', url : "https://drive.google.com/file/d/1mzZT4wqT75VOo08xoKI1KOLkW6CUvFtT/view?usp=sharing"},
                    { title : 'How to set Work Schedule', url : "https://drive.google.com/file/d/1o6YPPIoOGFX-qedrIbaKl_U8KsAfL6Lo/view?usp=sharing"},
                ],
                [
                    { title : 'Organization Setting', url : "https://drive.google.com/file/d/14jaE20TtI904Er8k9kt3V-_7SxyzLI21/view?usp=sharing"},
                    { title : 'Tier', url : "https://drive.google.com/file/d/113UwM1o0ukxQMtHfMeDnJnaB0v6VnUkW/view?usp=sharing"},
                ],
                [
                    { title : 'Attendance Setting', url : "https://drive.google.com/file/d/10BTobY91_ggkjKeeLfBgvaPHs03OIgdl/view?usp=sharing" },
                    { title : 'How to set filing', url : "https://drive.google.com/file/d/10qi5EFWX7OncJn60rOXEbNSk8wDRZJKd/view?usp=sharing" },
                ],
                [
                    { title : "Invite Employees", url : "https://drive.google.com/file/d/1YQsUiDVkWB9Nc-Mtv93z9zp3ly2oK22m/view?usp=sharing" },
                    { title : "Invite Employees in Bulk", url : "https://drive.google.com/file/d/1WhTcwWLnugpUwJKIlWhk9uEpj6kai7By/view?usp=sharing" },
                    { title : "Employee Setting", url : "https://drive.google.com/file/d/1je5w7CO-v95NJ1novCdYi0c7VOZ_Vp2y/view?usp=sharing" },
                    { title : "Admin Settings", url : "https://drive.google.com/file/d/1dX3I0iFUzsaUKB-WyBUemq1uoT6f1aMz/view?usp=sharing" },
                ],
                [
                    { title : "Annual Leave Setting", url : "https://drive.google.com/file/d/1PE3iPVKvX4Pt4AP157AGlQFprBzQ0dpV/view?usp=sharing" },
                    { title : "Leave for Non-Regular Employees", url : "https://drive.google.com/file/d/16xEzZHc61iqBMyhgMHFl7DobEEVSL1f_/view?usp=sharing" },
                    { title : "Other Leave Type", url : "https://drive.google.com/file/d/1ZKxWL9Vd6GOs3lCdPxvPt1issv5atNR6/view?usp=sharing" },
                    { title : "Grant Leave in Bulk", url : "https://drive.google.com/file/d/17CnUPPaQs9ZjGNlezraQqc9AvcVBbxov/view?usp=sharing" },
                ],
                [
                    { title : "How to Top-up FlexBen points" , url : "https://drive.google.com/file/d/1F60n2GLq-WKoto9UUUy3HvJsdsYcFaP-/view?usp=sharing" },
                    { title : "How to transfer points" , url : "https://drive.google.com/file/d/1D0_78tlpvNDh1eWpr2C18HwMer_6DZWj/view?usp=sharing" },
                    { title : "How to transfer function" , url : "https://drive.google.com/file/d/1LakC9H-NB6pTbXKyCVWMZpnvb9TMOfT1/view?usp=sharing" },
                ],
                [
                    {title : "Enter Employee Salary Information", url : "https://drive.google.com/file/d/121pj72ww3TKMQrChzKCCSVToo1tamKAi/view?usp=sharing"},
                    {title : "Add Payroll Template", url : "https://drive.google.com/file/d/1-nO1fIqEGJQsW32Xe7yCH36HBNSw8mg0/view?usp=sharing"},
                    {title : "Update Year-to-Date Payroll", url : "https://drive.google.com/file/d/1rLpDZ_8OHEww9Yu4foRxa44Nv8-3T-bt/view?usp=sharing"},
                    {title : "Set up Employee Loans Information", url : "https://drive.google.com/file/d/1izXmjBBsPMF_tydgM80HRPMOXt0GpWlW/view?usp=sharing"},
                    {title : "Payroll Processing", url : "https://drive.google.com/file/d/1keJjE3-THPi8RIkG5g9_aXv_b3NwbEpD/view?usp=sharing"},
                    {title : "Payslip for employees", url : "https://drive.google.com/file/d/1D8Rq-xmZ0G1KO2cg_qNDx0nQya_3VuFd/view?usp=sharing"},
                    {title : "Final pay", url : "https://drive.google.com/file/d/1I9C15yr6PsvBK4nZXEm5JruQoMZiJl1i/view?usp=sharing"},
                    {title : "Report - BIR Form 1601-C", url : "https://drive.google.com/file/d/1Nkdybl3F95IK3v_H-MjR-hk6iwlsqUER/view?usp=sharing"}
                ]
            ]
        }
    }

}
</script>