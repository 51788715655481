<template>
    <div>
		<section class="left w-[400px] bg-white border rounded shadow box-style-border">
            <div class="header flex items-center justify-between py-[22px] px-[26px] border-gray-300 border-b-[1px]">
                <span class="text-[20px] font-bold text-[#2E2C34]" @click="getAllEmployee"> {{ companyName }} </span>
            </div>
            <div class="overflow-hidden contents-container py-[10px] mx-2">
				<div v-for="(department, index) in showDepartments" v-bind:key="index">
                    <div
						@click="clickDepartment(department)"
                        class="relative cursor-pointer h-[60px] flex justify-between items-center hover:bg-gray-100"
                        :class="{ 'mt-4': index === 0 }"
                        :style="{'padding-left': `${  20 * department.level }px`,}"
                    >
						<img
							:src="require(`@/assets/img/department-${department.level + 1}.png`)"
							:alt="`department-${department.level}`"
							width="20"
						/>
                        <div class="flex flex-1 gap-1 ml-3 pr-5 w-full">
                            <div class="max-w-10/12 font-medium truncate">
                                {{ department.departmentName }}
                            </div>
                            <div class="text-gray-700">
                                {{ `(${ department.employeeCount})`}}
                            </div>
                        </div>
						<img
							:src="require(`@/assets/img/department-${c.top}.png`)"
							class="absolute top-0 w-[20px] h-[20px]"
							:style="{left: `${ 20 * index }px`,}"
							v-for="(c, index) in department.css"
							v-bind:key="index"
						/>
						<img
							:src="require(`@/assets/img/department-${c.middle}.png`)"
							class="absolute top-[20px] w-[20px] h-[20px]"
							:style="{left: `${ 20 * index }px`}"
							v-for="(c, index) in department.css"
							v-bind:key="index"
						/>
						<img
							:src="require(`@/assets/img/department-${c.bottom}.png`)"
							class="absolute bottom-0 h-[20px]"
							:style="{left: `${ 20 * index }px`}"
							v-for="(c, index) in department.css"
							v-bind:key="index"
						/>
					</div>
                </div>
			</div>
		</section>
    </div>
</template>

<script>
export default {
    data(){
        return{
            departments : [],
			showDepartments : [],
			returnSubArray : [],
			companyName : "",
        }
    },
	mounted(){
		const self = this;
		self.getDepartmentData();
        self.getCompanyName();
	},
	emits : ["clickDepartment"],
    methods:{
        getAllEmployee(){
            const self = this;
            self.$emit('clickDepartment')
        },
        getCompanyName(){
            const self = this;
            const url = self.$api("uri", "get-company")
            self.$axios.get(url)
                .then(res => {
                    self.companyName = res.data.data.companyName
                })
        },
        clickDepartment(item){
			const self = this;
			self.$emit('clickDepartment', item.departmentSeq );
		},
		getDepartmentData() {
            const self = this;
            let getId = [];
            self.showDepartments = [];
            self.returnSubArray = [];

            let url = self.$api("uri", "get-department");

            const json_query = JSON.stringify({
                showRootNode: true,
                excludeEmployeeStatusList : [
                    "RESIGNED"
                ]
            });

            self.$axios
                .get(url, { params: { json_query } })
                .then(function (res) {
                    self.departments = res.data.data.list;

					self.pushToShowDepartments(self.findTopLevel());
					getId.push(self.findTopLevel().departmentSeq);
                    self.pushToShowDepartments(self.findUpperIsTopId(getId, 1));

					return self.showDepartments
                })
				.then( departmentTree => {
					//CSS 출력
                    for (let i = 0; i < departmentTree.length; i++) {
                        self.drawMyLevelCss(i);
                    }
				})
                .catch((error) => {
                    console.error("error => ", error);
                });
        },
		pushToShowDepartments(department) {
            const self = this;

            if (department.length == null) {
                self.showDepartments.push({ ...department, showSub: false });
            }

            for (let i = 0; i < department.length; i++) {
                self.showDepartments.push({ ...department[i], showSub: false });
            }
        },
		findTopLevel() {
            const self = this;
            const length = self.departments.length;

            for (let i = 0; i < length; i++) {
				if (self.departments[i].parentDepartmentSeq == null) {
					self.departments[i].level = 0;
                    return self.departments[i];
                }
            }
        },
		drawMyLevelCss(index) {
            //전역
            const self = this;
            const department = self.showDepartments[index];
            const myLevel = department.level;
            let cssArr = [];

            //init
            self.returnSubArray = [];

            //0. root node
            if (self.findChildList(department.departmentSeq).length == 0) {
                cssArr.unshift({ top: 15, middle: myLevel + 1, bottom: 15 });
            } else {
                cssArr.unshift({ top: 15, middle: myLevel + 1, bottom: 12 });
            }

            //1. level 1
            if (myLevel >= 1) {
                if (self.findMyPosition(department, myLevel))
                    cssArr.unshift({ top: 12, middle: 11, bottom: 15 });
                else cssArr.unshift({ top: 12, middle: 13, bottom: 12 });
            }

            //2. level n (1 이상)
            if (myLevel > 1) self.drawMyNextLevelCss(department, cssArr);

            //적용
            department.css = cssArr;
        },
		findUpperIsTopId(id, level) {
            const self = this;

            for (let i = 0; i < self.departments.length; i++) {
                if (self.departments[i].parentDepartmentSeq == id) {
                    self.departments[i].level = level ;
                    self.returnSubArray.push(self.departments[i]);
                    self.findUpperIsTopId(
                        self.departments[i].departmentSeq,
                        level + 1
                    );
                }
            }
            return self.returnSubArray;
        },
		findChildList(id) {
            const self = this;
            const returnArr = [];

            self.findUpperIsTopId(id).forEach((d) => {
                returnArr.push(d.departmentSeq);
            });

            return returnArr;
        },
		findMyPosition(department) {
            const self = this;
            const siblingArr = [];
            const parentSeq = department.parentDepartmentSeq;
            let isLast = false;

            self.showDepartments.forEach((d) => {
                if (d.parentDepartmentSeq == parentSeq)
                    siblingArr.push(d.departmentSeq);
            });

            const myLevelIndex = siblingArr.indexOf(department.departmentSeq);
            if (siblingArr.length == myLevelIndex + 1) isLast = true;
            else isLast = false;

            return isLast;
        },
		drawMyNextLevelCss(department, cssArr) {
            const self = this;
            const parentSeq = department.parentDepartmentSeq;
            const parent = self.showDepartments.find(
                (d) => d.departmentSeq == parentSeq
            );
            const parentLevel = parent.level;

            if (self.findMyPosition(parent, parentLevel))
                cssArr.unshift({ top: 15, middle: 15, bottom: 15 });
            else cssArr.unshift({ top: 12, middle: 12, bottom: 12 });

            //언제까지 ? deparmt의 레벨이 2보다 작아지면 ?
            if (parentLevel > 1) self.drawMyNextLevelCss(parent, cssArr);

            return cssArr;
        },
    }
}
</script>
<style scoped>
.box-style-border{
    border: 1px solid #D1D5DB;
}
</style>