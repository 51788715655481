<script>
import moment from "moment";

export default {
    components: {
    },
    props: {
        selectedEmployeeSeq: String,
    },
    data() {
        return {
            documentConfirm: null,
            finalConfirmDatetime: null,
            documentList: null,
            documentUploadList: null,
        }; // return
    }, // data
    mounted() {
        const self = this;
        self.getDocumentConfirm();
    },
    methods: {
        moment: moment,
        parseInt: parseInt,
        getDocumentConfirm() {
            const self = this;
            const url = self.$api("uri", "get-document-confirm-check");

            const json_query = JSON.stringify({
                employeeSeq: self.selectedEmployeeSeq,
            });

            self.$axios.get(url ,{ params : { json_query } }).then((res) => {
                self.documentConfirm = res.data.data;
                const {documentEmployeeInfo} = self.documentConfirm;
                if (documentEmployeeInfo && documentEmployeeInfo.finalConfirmYn == 'Y') {
                    self.finalConfirmDatetime = documentEmployeeInfo.finalConfirmDatetime;
                    //업로드 리스트 조회
                    self.getDocumentEmployeeUpload();
                } else {
                    //컴퍼니 세팅 조회
                    self.getDocumentCompanyPolicy();
                }
            });
        },
        getDocumentCompanyPolicy() {
            const self = this;
            let url = self.$api('uri', 'get-document-company-policy');
            self.$axios
                .get(url)
                .then((res) => {
                    self.documentList = res.data.data.list;
                    console.log(self.documentList);
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        getDocumentEmployeeUpload() {
            const self = this;
            let url = self.$api('uri', 'get-document-employee-upload');

            const json_query = JSON.stringify({
                employeeSeq: self.selectedEmployeeSeq,
            });

            self.$axios
                .get(url, { params : { json_query } })
                .then((res) => {
                    self.documentUploadList = res.data.data.list;
                    console.log(self.documentUploadList);
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        downloadDocument(url) {
            const downloadLink = url;
            const aElem = document.createElement('a');
            aElem.href = downloadLink;
            aElem.click();
        },
    },
}; // return
</script>
<template>
    <div>
        <div class="flex flex-col justify-between mt-6 h-10 p-2">
            <div class="flex justify-between items-center h-10">
                <h4 class="text-[18px] font-bold">
                    Employee Documents
                </h4>
            </div>
            <div v-if="finalConfirmDatetime">
                <div class="flex items-center text-[15px] text-[#6B7280] mt-1">
                    <div class="mr-1">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.625" y="0.625" width="14.75" height="14.75" rx="7.375" fill="#3C82F6"/>
                            <rect x="0.625" y="0.625" width="14.75" height="14.75" rx="7.375" stroke="#3C82F6"
                                  stroke-width="1.25"/>
                            <path d="M4.7998 8.13615L7.0638 10.5361L11.5998 5.73615" stroke="white" stroke-width="1.875"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    Last update : {{ moment(finalConfirmDatetime).format("MM/DD/YYYY HH:mm:ss") }}
                </div>
            </div>
            <div v-else>
                <div class="flex items-center text-[15px] text-[#6B7280] mt-1">
                    <div class="mr-1">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.625" y="0.625" width="14.75" height="14.75" rx="7.375" fill="#EA3323"/>
                            <rect x="0.625" y="0.625" width="14.75" height="14.75" rx="7.375" stroke="#EA3323"
                                  stroke-width="1.25"/>
                            <path d="M5 11L11 5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M11 11L5 5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </div>
                    This employee has not uploaded the documents.
                </div>
            </div>
        </div>

        <div class="mt-[2rem] w-full">
            <div>
                <template v-for="(document, index) in documentList" v-bind:key="index">
                    <div class="w-full h-[3.875rem] flex justify-between items-center p-2"
                         :class="{'bg-[#F8F8FD]':index%2 != 0}">
                        <div>{{document.documentName}}<span v-if="document.documentRequirement == 'REQUIRED'" class="text-[#4261EE]">*</span></div>
                        <div>
                            <button class="w-[9.375rem] h-[2.5rem] rounded-[8px] bg-[#F0F0F0] text-[#6B7280]" disabled>
                                Download
                            </button>
                        </div>
                    </div>
                </template>
                <template v-for="(documentUpload, index) in documentUploadList" v-bind:key="index">
                    <div class="w-full h-[3.875rem] flex justify-between items-center p-2"
                         :class="{'bg-[#F8F8FD]':index%2 != 0}">
                        <div>{{documentUpload.documentName}}<span v-if="documentUpload.documentRequirement == 'REQUIRED'" class="text-[#4261EE]">*</span></div>
                        <div>
                            <button class="w-[9.375rem] h-[2.5rem] border border-[#3C82F6] rounded-[8px] text-[#3C82F6] hover:bg-[#E1EFFD]"
                                @click="downloadDocument(documentUpload.documentUploadFilePath)">
                                Download
                            </button>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
