
<template>
    <div v-if="modelValue" class="fixed top-0 w-full h-full bg-black/50 z-30 flex justify-center items-center">
        <div class="border rounded-lg shadow shadow-gray-100 bg-white min-w-[644px]">
            <div class="px-7 py-6 w-full flex justify-between items-center">
                <span class="text-xl font-bold">
                    Download Report
                </span>
                <a href="javascript:void(0)" class="pl-40" @click="$emit('update:modelValue', false)">
                    <img src="@/assets/img/app-popup-1.svg" alt="app-popup-1" />
                </a>
            </div>

            <div class="w-full h-px bg-gray-200"></div>

            <div class="w-full px-7 py-6 flex justify-between items-center">
                <div>
                    <span class="text-xl font-medium">Select Employee({{ selectedEmployeeCount }})</span>
                </div>
                <div>
                    <input
                        type="text"
                        placeholder="Search by Name"
                        class="border-gray-300 border-[1px] rounded mr-[10px] w-72 h-[48px]"
                        v-model.trim="nameForSearch"
                        @keyup="changeNameForSearch"
                    />
                </div>
            </div>


            <div class="h-12 flex justify-between bg-gray-100">
                <p class="text-center my-auto pl-6 text-gray-900 text-base font-medium w-[10%]">
                    <input
                        v-model="allCheck.isChecked"
                        :id="allCheck.value"
                        type="checkbox"
                        class="h-5 p-3 w-5 rounded border-gray-300  focus:ring-indigo-500"
                        @input="clickAllCheckbox"
                    />
                </p>
                <p class="text-center my-auto text-gray-500 text-base font-medium w-[40%]">Name</p>
                <p class="text-center my-auto pl-6 text-gray-500 text-base font-medium w-[50%]">Department/Tier</p>
            </div>
            <div class="w-full m-auto rounded-lg border-1 h-40 overflow-y-auto">
                <table class="w-full">
                    <tbody class="divide-y divide-gray-200  border-b">
                        <tr class="border-x border-y h-12" v-for="(employee, index) in viewList" v-bind:key="index">
                            <td class="w-[10%] whitespace-nowrap pl-6 py-3 text-center text-sm font-medium text-gray-900">
                                <input
                                    v-model="employee.isChecked"
                                    :id="employee.payrollProcessEmployeeSeq ? employee.payrollProcessEmployeeSeq : employee.payrollProcessEmployeeHistorySeq"
                                    type="checkbox"
                                    class="h-5 p-3 w-5 rounded border-gray-300  focus:ring-indigo-500"
                                />
                            </td>
                            <td class="w-[40%] whitespace-nowrap py-3 text-center text-sm font-medium text-gray-900">{{ employee.employeeName }}</td>
                            <td class="w-[50%] whitespace-nowrap pl-6 py-3 text-center text-sm font-medium text-gray-900">{{ employee.departmentName }} / {{ employee.jobTitleName }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="w-full px-7 py-2 flex-col justify-between items-center">
                <div>
                    <span class="text-xl font-medium">Select Type</span>
                </div>
                <div class="mt-3 ml-5 flex items-center">
                    <input
                        v-model="summaryEmployer.isChecked"
                        :id="summaryEmployer.value"
                        type="checkbox"
                        class="h-5 p-3 w-5 rounded border-gray-300  focus:ring-indigo-500"
                    />
                    <div class="ml-3 text-sm font-medium leading-6">
                        <label :for="summaryEmployer.value" class="font-medium text-gray-900">{{ summaryEmployer.text }}</label>
                    </div>
                </div>
                <div class="mt-3 ml-5 flex items-center">
                    <input
                        v-model="summaryEmployee.isChecked"
                        :id="summaryEmployee.value"
                        type="checkbox"
                        class="h-5 p-3 w-5 rounded border-gray-300  focus:ring-indigo-500"
                    />
                    <div class="ml-3 text-sm font-medium leading-6">
                        <label :for="summaryEmployee.value" class="font-medium text-gray-900">{{ summaryEmployee.text }}</label>
                    </div>
                </div>
                <div class="mt-3 ml-5 flex items-center">
                    <input
                        v-model="payslipEmployee.isChecked"
                        :id="payslipEmployee.value"
                        type="checkbox"
                        class="h-5 p-3 w-5 rounded border-gray-300  focus:ring-indigo-500"
                    />
                    <div class="ml-3 text-sm font-medium leading-6">
                        <label :for="payslipEmployee.value" class="font-medium text-gray-900">{{ payslipEmployee.text }}</label>
                    </div>
                </div>
                <div v-if="isFinalPay(payrollType)" class="mt-3 ml-5 flex items-center">
                    <input
                        v-model="bir2316.isChecked"
                        :id="bir2316.value"
                        type="checkbox"
                        class="h-5 p-3 w-5 rounded border-gray-300  focus:ring-indigo-500"
                    />
                    <div class="ml-3 text-sm font-medium leading-6">
                        <label :for="bir2316.value" class="font-medium text-gray-900">{{ bir2316.text }}</label>
                    </div>
                </div>
            </div>

            <div class="mt-3 max-h-[50vh]">
                <div class="w-full h-px bg-gray-200"></div>
                <div class="px-4 py-6 flex justify-end gap-2">
                    <button
                        class="w-28 h-12 bg-white rounded  flex items-center gap-2 justify-center text-gray-500 font-semibold text-sm border border-gray-300"
                        type="button"
                        @click="$emit('update:modelValue', false)"
                    >
                        Cancel
                    </button>
                    <button
                        class="w-28 h-12 bg-indigo-600 rounded  flex items-center gap-2 justify-center text-white font-semibold text-sm"
                        type="submit"
                        @click="downloadReport"
                    >
                        Download
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CssClassUtil } from "@/plugins/app-util";
import { PAYROLL_TYPE } from "@/pages/payroll/payroll_processing/validation/constants/payroll-validation-enum";

export default {
    props: {
        modelValue : Boolean,
        employeeList : Array,
        payrollType : String
    },
    watch : {
        modelValue( newVal ){
            if( ! newVal ) return;

            const self = this;
            self.nameForSearch = '';
            self.viewList = [ ...self.employeeList ];
            self.allCheck.isChecked = false;
            self.summaryEmployee.isChecked = false;
            self.summaryEmployer.isChecked = false;
            self.payslipEmployee.isChecked = false;
            self.bir2316.isChecked = false;
        },
        viewList : {
            handler () {
                const self = this;
                self.selectedEmployeeCount = 0;
                self.viewList.forEach(element => {
                    if (element.isChecked) {
                        ++self.selectedEmployeeCount;
                    }
                });
            },
            deep : true
        }
    },
    emits: [
        'download-report',
        'update:modelValue'
    ],
    components: {
    },
    mounted() {
    },
    data() {
        return {
            nameForSearch : '',
            viewList : [],
            selectedEmployeeCount : 0,
            allCheck : {
                isChecked : false,
                text : "",
                value : "ALL"
            },
            summaryEmployee : {
                isChecked : false,
                text : "Payroll Summary for employee",
                value : "SUMMARY_EMPLOYEE"
            },
            summaryEmployer : {
                isChecked : false,
                text : "Payroll Summary for employer",
                value : "SUMMARY_EMPLOYER"
            },
            payslipEmployee : {
                isChecked : false,
                text : "Payslip for employee",
                value : "PAYSLIP_EMPLOYEE"
            },
            bir2316 : {
                isChecked : false,
                text : "BIR From NO.2316",
                value : "BIR_2316"
            }
        };
    },
    methods: {
        changeNameForSearch(){
            const self = this;
            let searchTimer;

            clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
                self.viewList = self.getEmployeeListBySearch(self.nameForSearch);
            }, 500);
        },
        getEmployeeListBySearch(searchKeyword) {
            const self = this;
            return self.employeeList.filter(emp => String(emp.employeeName).toLowerCase().startsWith(String(searchKeyword).toLowerCase()));
        },
        getColor(seq) {
            return CssClassUtil.getProfileBackColor(seq);
        },
        downloadReport(){
            const self = this;
            let reportCondition = {
                employeeList : [],
                summaryEmployer : self.summaryEmployer.isChecked,
                summaryEmployee : self.summaryEmployee.isChecked,
                payslipEmployee : self.payslipEmployee.isChecked,
                bir2316 : self.bir2316.isChecked
            }
            self.viewList.forEach(element => {
                if (element.isChecked) {
                    reportCondition.employeeList.push(element);
                }
            });

            if (reportCondition.employeeList.length < 1) {
                alert("Please select at least one employee!");
                return;
            }

            // 모두가 false 일 때
            if (!reportCondition.summaryEmployer && !reportCondition.summaryEmployee && !reportCondition.payslipEmployee && !reportCondition.bir2316) {
                if (self.isFinalPay(self.payrollType)) {
                    alert("You must choose one of these.\r\n'Payroll Summary for employee'\r\n'Payroll Summary for employer'\r\n'Payslip for employee'\r\n'BIR From NO.2316''");
                } else {
                    alert("You must choose one of these.\r\n'Payroll Summary for employee'\r\n'Payroll Summary for employer'\r\n'Payslip for employee'");
                }
                return;
            }
            self.$emit('download-report', reportCondition);
        },
        clickAllCheckbox() {
            const self = this;
            self.allCheck.isChecked = !self.allCheck.isChecked;
            self.viewList.forEach((item) =>{
                if (item.isChecked != null) {
                    item.isChecked = self.allCheck.isChecked;
                }
            });
        },
        isFinalPay(payrollType) {
            return PAYROLL_TYPE.FINAL_PAY === payrollType;
        }
    },
};
</script>
