<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
    modelValue: Boolean,
    confirmDescription: String,
    buttonText: String,
    isBold: { type: Boolean, default: true },
    isTextLg: { type: Boolean, default: false },
});

const emit = defineEmits(['update:modelValue']);

function hidePopup() {
    emit('update:modelValue', false);
}
</script>

<template>
    <div v-show="modelValue" class="fixed top-0 w-full h-full bg-black/50 z-[80] flex justify-center items-center">
        <div class="w-[26.25rem] h-52 border rounded-lg shadow shadow-gray-100 bg-white flex flex-col justify-center items-center gap-6">
            <p class="text-[#504F54] text-center" :class="[isBold ? 'font-bold' : 'font-normal', isTextLg ? 'text-lg' : 'text-xl']">
                {{ confirmDescription }}
            </p>
            <button @click="hidePopup" class="w-40 h-12 border rounded-lg bg-[#4261EE] text-white text-base font-semibold">
                {{ buttonText }}
            </button>
        </div>
    </div>
</template>
